import React, {
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState
} from "react";
import { indexOf, set } from "lodash";
import { gsap } from "gsap";
import { KalaJamText } from "../PilowlavaText/KalaJamText";
import { EncyclopediaContent } from "../../utils/encyclopedia-content";
import { PantuaText } from "../PilowlavaText/PantuaText";
import { GulabJamunText } from "../PilowlavaText/GulabJamunText";
import { LedikeniText } from "../PilowlavaText/LedikeniText";
import { JhurreKaRasgullaText } from "../PilowlavaText/JhurraText";
import { RasgullaText } from "../PilowlavaText/RasgullaText";
import { RoundPlanet } from "./3DPlanet";
import { TextWithHotspot } from "../PilowlavaText";
import { IAnyPlanet, IHotspotMapItem, IHotspotPosition } from "./types";
import Portal from "../Portal";

export interface IPlanetForJV {
    progress: number;
    isActive: boolean;
    initialHotspotX: number;
    initialHotspotY: number;
    onPrevClick: () => void;
    onNextClick: () => void;
    setActiveTl: (GSAPTimeline) => void;
    onPortalReady: () => void;
    exitTimeout: number;
}

const SEQUENCE: IAnyPlanet[] = [
    "pantua",
    "rasgulla-sun",
    "kalajam",
    "gulabjamun",
    "jhurra",
    "ledikeni"
];

const getNextInSequence = (current: IAnyPlanet) => {
    const currentIndex = indexOf(SEQUENCE, current);
    if (currentIndex === SEQUENCE.length - 1) {
        return SEQUENCE[0];
    }
    return SEQUENCE[currentIndex + 1];
};

const getPrevInSequence = (current: IAnyPlanet) => {
    const currentIndex = indexOf(SEQUENCE, current);
    if (currentIndex === 0) {
        return SEQUENCE[SEQUENCE.length - 1];
    }
    return SEQUENCE[currentIndex - 1];
};
export const KalajamForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal selector="#jamunverse-kalajam-portal" onMount={onPortalReady}>
            <KalaJamText
                exitTimeout={exitTimeout}
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                // spreadFactor={5}
                // line2TranslateX={10}
                body1={EncyclopediaContent.kalajam.body1}
                body2={EncyclopediaContent.kalajam.body2}
                isActive={isActive}
                showProgress={progress}
                circleWidthPercentage={15}
                textInitialScale={0.35}
                svgPathOffsetXPercentage={6}
                svgPathOffsetYPercentage={-3}
            />
        </Portal>
    )
);

export const PantuaForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal selector="#jamunverse-pantua-portal" onMount={onPortalReady}>
            <PantuaText
                exitTimeout={exitTimeout}
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                body1={EncyclopediaContent.pantua.body1}
                body2={EncyclopediaContent.pantua.body2}
                isActive={isActive}
                showProgress={progress}
                circleWidthPercentage={11}
                textInitialScale={0.3}
                svgPathOffsetXPercentage={-2}
            />
        </Portal>
    )
);

export const GulabJamunForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal
            selector="#jamunverse-gulabjamun-portal"
            onMount={onPortalReady}
        >
            <GulabJamunText
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                body1={EncyclopediaContent.gulabjamun.body1}
                body2={EncyclopediaContent.gulabjamun.body2}
                isActive={isActive}
                showProgress={progress}
                exitTimeout={exitTimeout}
                circleWidthPercentage={14}
                textInitialScale={0.35}
                svgPathOffsetXPercentage={4.4}
            />
        </Portal>
    )
);

export const LedikeniForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal selector="#jamunverse-ledikeni-portal" onMount={onPortalReady}>
            <LedikeniText
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                body1={EncyclopediaContent.ledikeni.body1}
                body2={EncyclopediaContent.ledikeni.body2}
                isActive={isActive}
                showProgress={progress}
                exitTimeout={exitTimeout}
                circleWidthPercentage={17}
                textInitialScale={0.4}
                svgPathOffsetXPercentage={7}
                svgPathOffsetYPercentage={9}
            />
        </Portal>
    )
);

export const JhurraForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal selector="#jamunverse-jhurra-portal" onMount={onPortalReady}>
            <JhurreKaRasgullaText
                exitTimeout={exitTimeout}
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                body1={EncyclopediaContent.jhurra.body1}
                body2={EncyclopediaContent.jhurra.body2}
                isActive={isActive}
                showProgress={progress}
                circleWidthPercentage={22}
                textInitialScale={0.3}
                svgPathOffsetXPercentage={8}
                svgPathOffsetYPercentage={-4}
            />
        </Portal>
    )
);

export const RasgullaForJV = React.memo(
    ({
        progress,
        isActive,
        initialHotspotX,
        initialHotspotY,
        onNextClick,
        onPrevClick,
        setActiveTl,
        onPortalReady,
        exitTimeout
    }: IPlanetForJV) => (
        <Portal selector="#jamunverse-rasgulla-portal" onMount={onPortalReady}>
            <RasgullaText
                exitTimeout={exitTimeout}
                setActiveTl={setActiveTl}
                onNextClick={onNextClick}
                onPrevClick={onPrevClick}
                initialHotspotX={initialHotspotX}
                initialHotspotY={initialHotspotY}
                body1={EncyclopediaContent["rasgulla-sun"].body1}
                body2={EncyclopediaContent["rasgulla-sun"].body2}
                isActive={isActive}
                showProgress={progress}
                circleWidthPercentage={13}
                textInitialScale={0.35}
                svgPathOffsetXPercentage={4}
                svgPathOffsetYPercentage={4}
            />
        </Portal>
    )
);

export const Planets = React.memo(
    ({
        hotspotsEnabled,
        openPlanet,
        scrollValue,
        setOpenPlanet,
        setPantuaTextActiveTl,
        setJhurraTextActiveTl,
        setGulabjamunTextActiveTl,
        setRasgullaTextActiveTl,
        setKalajamTextActiveTl,
        setLedikeniTextActiveTl,
        setPantuaPlanetActiveTl,
        setJhurraPlanetActiveTl,
        setGulabjamunPlanetActiveTl,
        setRasgullaPlanetActiveTl,
        setKalajamPlanetActiveTl,
        setLedikeniPlanetActiveTl,
        onPlanetPortalsReady,
        exitTimeout
    }: {
        hotspotsEnabled: boolean;
        openPlanet: IAnyPlanet;
        scrollValue: number;
        setOpenPlanet: (a: IAnyPlanet) => void;
        setPantuaTextActiveTl: (GSAPTimeline) => void;
        setJhurraTextActiveTl: (GSAPTimeline) => void;
        setGulabjamunTextActiveTl: (GSAPTimeline) => void;
        setRasgullaTextActiveTl: (GSAPTimeline) => void;
        setKalajamTextActiveTl: (GSAPTimeline) => void;
        setLedikeniTextActiveTl: (GSAPTimeline) => void;
        setPantuaPlanetActiveTl: (GSAPTimeline) => void;
        setJhurraPlanetActiveTl: (GSAPTimeline) => void;
        setGulabjamunPlanetActiveTl: (GSAPTimeline) => void;
        setRasgullaPlanetActiveTl: (GSAPTimeline) => void;
        setKalajamPlanetActiveTl: (GSAPTimeline) => void;
        setLedikeniPlanetActiveTl: (GSAPTimeline) => void;
        onPlanetPortalsReady: () => void;
        exitTimeout: number;
    }) => {
        const mainRef = useRef();

        const [gulabJamunPortalReady, setGulabJamunPortalReady] =
            useState(false);
        const [pantuaPortalReady, setPantuaPortalReady] = useState(false);
        const [rasgullaPortalReady, setRasgullaPortalReady] = useState(false);
        const [jhurraPortalReady, setJhurraPortalReady] = useState(false);
        const [ledikeniPortalReady, setLedikeniPortalReady] = useState(false);
        const [kalajamPortalReady, setKalajamPortalReady] = useState(false);

        useEffect(() => {
            if (
                gulabJamunPortalReady &&
                pantuaPortalReady &&
                rasgullaPortalReady &&
                jhurraPortalReady &&
                ledikeniPortalReady &&
                kalajamPortalReady
            ) {
                onPlanetPortalsReady();
            }
        }, [
            gulabJamunPortalReady,
            pantuaPortalReady,
            rasgullaPortalReady,
            jhurraPortalReady,
            ledikeniPortalReady,
            kalajamPortalReady
        ]);

        const [hotspotPositionPantua, setHotspotPositionPantua] =
            useState<IHotspotPosition>({});

        const [hotspotPositionRasgulla, setHotspotPositionRasgulla] =
            useState<IHotspotPosition>({});

        const [hotspotPositionJhurra, setHotspotPositionJhurra] =
            useState<IHotspotPosition>({});

        const [hotspotPositionGulabJamun, setHotspotPositionGulabJamun] =
            useState<IHotspotPosition>({});

        const [hotspotPositionLedikeni, setHotspotPositionLedikeni] =
            useState<IHotspotPosition>({});

        const [hotspotPositionKalajam, setHotspotPositionKalajam] =
            useState<IHotspotPosition>({});

        const [hotspotTextPositionPantua, setHotspotTextPositionPantua] =
            useState<IHotspotPosition>({});

        const [hotspotTextPositionRasgulla, setHotspotTextPositionRasgulla] =
            useState<IHotspotPosition>({});

        const [hotspotTextPositionJhurra, setHotspotTextPositionJhurra] =
            useState<IHotspotPosition>({});

        const [
            hotspotTextPositionGulabJamun,
            setHotspotTextPositionGulabJamun
        ] = useState<IHotspotPosition>({});

        const [hotspotTextPositionLedikeni, setHotspotTextPositionLedikeni] =
            useState<IHotspotPosition>({});

        const [hotspotTextPositionKalajam, setHotspotTextPositionKalajam] =
            useState<IHotspotPosition>({});

        const hotspotMap: IHotspotMapItem[] = useMemo(
            () => [
                {
                    key: "pantua",
                    setterHotspot: setHotspotPositionPantua,
                    setter: setHotspotTextPositionPantua,
                    relativePosition: "bottomRight"
                },
                {
                    key: "kalajam",
                    setterHotspot: setHotspotPositionKalajam,
                    setter: setHotspotTextPositionKalajam,
                    relativePosition: "bottomLeft"
                },
                {
                    key: "gulabjamun",
                    setterHotspot: setHotspotPositionGulabJamun,
                    setter: setHotspotTextPositionGulabJamun,
                    relativePosition: "bottomLeft"
                },
                {
                    key: "rasgulla-sun",
                    setterHotspot: setHotspotPositionRasgulla,
                    setter: setHotspotTextPositionRasgulla,
                    relativePosition: "topLeft"
                },
                {
                    key: "jhurra",
                    setterHotspot: setHotspotPositionJhurra,
                    setter: setHotspotTextPositionJhurra,
                    relativePosition: "bottomLeft"
                },
                {
                    key: "ledikeni",
                    setterHotspot: setHotspotPositionLedikeni,
                    setter: setHotspotTextPositionLedikeni,
                    relativePosition: "topLeft"
                }
            ],
            []
        );
        useLayoutEffect(() => {
            const ctx = gsap.context(() => {
                hotspotMap.forEach(
                    ({ key, setter, setterHotspot, relativePosition }) => {
                        const setPosition = () => {
                            const planetContainer =
                                gsap.utils.toArray<HTMLElement>(
                                    `.${key}-container`
                                )[0];
                            const planetPlanetBox = planetContainer
                                .querySelector(".planet")
                                .getBoundingClientRect();

                            const hotspotBox = planetContainer
                                .querySelector(".hotspot")
                                .getBoundingClientRect();
                            switch (relativePosition) {
                                case "bottomLeft":
                                    setter({
                                        // left: planetPlanetBox.x,
                                        left:
                                            planetPlanetBox.x -
                                            hotspotBox.width / 3,
                                        top:
                                            planetPlanetBox.y +
                                            planetPlanetBox.height -
                                            hotspotBox.height
                                    });
                                    setterHotspot({
                                        // left: planetPlanetBox.x,
                                        left: -hotspotBox.width / 3,
                                        top:
                                            planetPlanetBox.height -
                                            hotspotBox.height
                                    });
                                    return;

                                case "bottomRight":
                                    setter({
                                        left:
                                            planetPlanetBox.x +
                                            planetPlanetBox.width -
                                            hotspotBox.width +
                                            hotspotBox.width / 3,
                                        top:
                                            planetPlanetBox.y +
                                            planetPlanetBox.height -
                                            hotspotBox.height
                                    });
                                    setterHotspot({
                                        left:
                                            planetPlanetBox.width -
                                            hotspotBox.width +
                                            hotspotBox.width / 3,
                                        top:
                                            planetPlanetBox.height -
                                            hotspotBox.height
                                    });
                                    return;

                                case "topLeft":
                                    setter({
                                        left:
                                            planetPlanetBox.x -
                                            hotspotBox.width / 3,
                                        top:
                                            planetPlanetBox.y +
                                            hotspotBox.height / 3
                                    });
                                    setterHotspot({
                                        left: -hotspotBox.width / 3,
                                        top: -hotspotBox.height / 3
                                    });
                                    return;

                                case "topRight":
                                    setter({
                                        left:
                                            planetPlanetBox.x +
                                            planetPlanetBox.width -
                                            hotspotBox.width,
                                        top:
                                            planetPlanetBox.y -
                                            hotspotBox.height / 3
                                    });
                                    setterHotspot({
                                        left:
                                            planetPlanetBox.width -
                                            hotspotBox.width,
                                        top: -hotspotBox.height / 3
                                    });
                                    return;

                                default:
                                    setter({
                                        left: planetPlanetBox.x,
                                        top: planetPlanetBox.y
                                    });
                                    setterHotspot({
                                        left: 0,
                                        top: 0
                                    });
                            }
                        };

                        // if (!openPlanet) {
                        setPosition();
                        // window.addEventListener("resize", setPosition);
                        // } else {
                        //     // window.removeEventListener("resize", setPosition);
                        // }
                    }
                );
            }, mainRef);

            return () => {
                ctx.revert();
            };
        }, [scrollValue, hotspotsEnabled]);

        const [proximityEnabled, setProximityEnabled] = useState(false);

        useLayoutEffect(() => {

            let timeout = null;

            if(!openPlanet) {
                timeout = setTimeout(()=> {
                    setProximityEnabled(true);
                    // TODO - remove hack
                }, exitTimeout * 1.8)
            }
            else  {
                setProximityEnabled(false);
            }
            return () => {
                clearTimeout(timeout);
            }
        }, [openPlanet, exitTimeout])
        // useEffect(() => {
        //
        // }, [scrollValue]);
        return (
            <div ref={mainRef}>
                <div
                    className="rasgulla-sun-container"
                    css={{
                        position: "absolute",
                        top: "46%",
                        left: "48%"
                        // transform: "translate(-50%, -50%)"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="rasgulla-sun"
                        widthPercentage={7}
                        setActiveTl={setRasgullaPlanetActiveTl}
                    />
                    <TextWithHotspot
                        initialScale={0.9}
                        hotspotPositionOnly={hotspotPositionRasgulla}
                        {...hotspotTextPositionRasgulla}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("rasgulla-sun")}
                        children={(props) => (
                            <RasgullaForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() =>
                                    setRasgullaPortalReady(true)
                                }
                                setActiveTl={setRasgullaTextActiveTl}
                                isActive={openPlanet === "rasgulla-sun"}
                                onPrevClick={() =>
                                    setOpenPlanet(
                                        getPrevInSequence("rasgulla-sun")
                                    )
                                }
                                onNextClick={() =>
                                    setOpenPlanet(
                                        getNextInSequence("rasgulla-sun")
                                    )
                                }
                                {...props}
                            />
                        )}
                    />
                </div>

                <div
                    className="gulabjamun-container"
                    css={{
                        position: "absolute",
                        top: "42%",
                        left: "36.5%"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="gulabjamun"
                        widthPercentage={8}
                        setActiveTl={setGulabjamunPlanetActiveTl}
                    />
                    <TextWithHotspot
                        initialScale={0.9}
                        hotspotPositionOnly={hotspotPositionGulabJamun}
                        {...hotspotTextPositionGulabJamun}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("gulabjamun")}
                        children={(props) => (
                            <GulabJamunForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() =>
                                    setGulabJamunPortalReady(true)
                                }
                                setActiveTl={setGulabjamunTextActiveTl}
                                isActive={openPlanet === "gulabjamun"}
                                onPrevClick={() =>
                                    setOpenPlanet(
                                        getPrevInSequence("gulabjamun")
                                    )
                                }
                                onNextClick={() =>
                                    setOpenPlanet(
                                        getNextInSequence("gulabjamun")
                                    )
                                }
                                {...props}
                            />
                        )}
                    />
                </div>

                <div
                    className="ledikeni-container"
                    css={{
                        position: "absolute",
                        bottom: "74%",
                        right: "21%"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="ledikeni"
                        widthPercentage={12}
                        setActiveTl={setLedikeniPlanetActiveTl}
                    />
                    <TextWithHotspot
                        hotspotPositionOnly={hotspotPositionLedikeni}
                        {...hotspotTextPositionLedikeni}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("ledikeni")}
                        children={(props) => (
                            <LedikeniForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() =>
                                    setLedikeniPortalReady(true)
                                }
                                setActiveTl={setLedikeniTextActiveTl}
                                isActive={openPlanet === "ledikeni"}
                                onPrevClick={() =>
                                    setOpenPlanet(getPrevInSequence("ledikeni"))
                                }
                                onNextClick={() =>
                                    setOpenPlanet(getNextInSequence("ledikeni"))
                                }
                                {...props}
                            />
                        )}
                    />
                </div>
                <div
                    className="pantua-container"
                    css={{
                        position: "absolute",
                        bottom: "52%",
                        right: "44.5%"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="pantua"
                        widthPercentage={6}
                        // left="10vw"
                        // top="10vw"
                        setActiveTl={setPantuaPlanetActiveTl}
                    />

                    <TextWithHotspot
                        initialScale={0.8}
                        hotspotPositionOnly={hotspotPositionPantua}
                        {...hotspotTextPositionPantua}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("pantua")}
                        children={(props) => (
                            <PantuaForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() => setPantuaPortalReady(true)}
                                setActiveTl={setPantuaTextActiveTl}
                                isActive={openPlanet === "pantua"}
                                onPrevClick={() =>
                                    setOpenPlanet(getPrevInSequence("pantua"))
                                }
                                onNextClick={() =>
                                    setOpenPlanet(getNextInSequence("pantua"))
                                }
                                {...props}
                            />
                        )}
                    />
                </div>

                <div
                    className="jhurra-container"
                    css={{
                        position: "absolute",
                        right: "12%",
                        top: "54%"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="jhurra"
                        widthPercentage={15}
                        // left="10vw"
                        // top="10vw"
                        setActiveTl={setJhurraPlanetActiveTl}
                    />

                    <TextWithHotspot
                        initialScale={1.5}
                        hotspotPositionOnly={hotspotPositionJhurra}
                        {...hotspotTextPositionJhurra}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("jhurra")}
                        children={(props) => (
                            <JhurraForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() => setJhurraPortalReady(true)}
                                setActiveTl={setJhurraTextActiveTl}
                                isActive={openPlanet === "jhurra"}
                                onPrevClick={() =>
                                    setOpenPlanet(getPrevInSequence("jhurra"))
                                }
                                onNextClick={() =>
                                    setOpenPlanet(getNextInSequence("jhurra"))
                                }
                                {...props}
                            />
                        )}
                    />
                </div>

                <div
                    className="kalajam-container"
                    css={{
                        position: "absolute",
                        bottom: "41.5%",
                        right: "22.3%"
                    }}
                >
                    <RoundPlanet
                        enabled={hotspotsEnabled}
                        scrollValue={scrollValue}
                        id="kalajam"
                        widthPercentage={10}
                        setActiveTl={setKalajamPlanetActiveTl}
                    />
                    <TextWithHotspot
                        initialScale={1.2}
                        hotspotPositionOnly={hotspotPositionKalajam}
                        {...hotspotTextPositionKalajam}
                        proximityDisabled={
                            !proximityEnabled || !hotspotsEnabled
                        }
                        onClick={() => setOpenPlanet("kalajam")}
                        children={(props) => (
                            <KalajamForJV
                                exitTimeout={exitTimeout}
                                onPortalReady={() =>
                                    setKalajamPortalReady(true)
                                }
                                setActiveTl={setKalajamTextActiveTl}
                                isActive={openPlanet === "kalajam"}
                                {...props}
                                onPrevClick={() =>
                                    setOpenPlanet(getPrevInSequence("kalajam"))
                                }
                                onNextClick={() =>
                                    setOpenPlanet(getNextInSequence("kalajam"))
                                }
                            />
                        )}
                    />
                </div>
            </div>
        );
    }
);
